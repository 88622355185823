import React, { useContext } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import Privacy from '../privacy/Privacy';

import { Context } from '../../context/Context';
import TermsDialog from '../dialogs/terms-dialog/TermsDialog';
import { useState } from 'react';
import HouseRulesDialog from '../dialogs/house-rules-dialog/HouseRulesDialog';
import PersonalDataDialog from '../dialogs/personal-data-dialog/PersonalDataDialog';

import Dankort from '../../assets/logos/DK_Logo_CMYK.png';
import Mastercard from '../../assets/logos/mastercard.svg';
import Visa from '../../assets/logos/visa.svg';
// import MobilePay from '../../assets/logos/mobilepay.png';

const FooterComponent = styled.div`
    bottom: 0;
    width: 100%;
    /* height: 2.5rem; */
    left: 7.5%;
    display: ${(props) =>
        props.path &&
        props.path.includes('kalender') &&
        document.body.offsetWidth < 960
            ? 'none'
            : 'block'};
    font-size: 80%;
    width: 85%;
    max-width: 1200px;
    margin: 1rem auto 4rem auto;
    border-top: 1px solid ${(props) => props.theme.palette.primary.contrastText};

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        width: 90%;
    }
`;

const FooterContentContainer = styled.div`
    padding-top: 3rem;
`;

const FooterBox = styled.div`
    color: #999999;
    margin-right: 1rem;
    margin-bottom: 1%;
    min-width: 180px;
    min-height: 180px;

    &.footer-box--first {
        position: relative;
    }
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        min-height: 0;
        margin-bottom: 2rem;
    }
`;

const FlexContainer = styled.div`
    display: flex;
    justify-content: space-between;
    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        display: block;
    }
`;

const FooterTitle = styled(Typography)`
    color: ${(props) => props.theme.palette.primary.contrastText};
    font-size: 0.8rem;
    margin-bottom: 1rem;
`;

const FooterText = styled(Typography)`
    font-size: 0.8rem;
    margin-bottom: 1rem;
    line-height: 1.5rem;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        margin-bottom: 0.5rem;
    }
`;

const FooterLink = styled.a`
    color: #999999;
`;
const CookiesLink = styled.p`
    color: #999999;
    cursor: pointer;
`;

const LogoText = styled(Typography)`
    color: ${(props) => props.theme.palette.primary.contrastText};
    font-size: 1.25rem;
`;

const SmallText = styled.div`
    padding-bottom: 1rem;
`;

const CardsLogoContainer = styled.div`
    display: flex;
    width: fit-content;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 1rem;
    position: absolute;
    bottom: 0;

    @media (max-width: ${(props) => props.theme.breakpoints.values.md}px) {
        position: static;
    }
`;
const Logo = styled.img`
    margin-top: 20px;
    margin-right: 10px;
    width: 38px;
    height: 25px;
`;

function Footer(props) {
    const [isTermsDialogOpen, setIsTermsDialogOpen] = useState(false);
    const [isHouseRulesDialogOpen, setIsHouseRulesDialogOpen] = useState(false);
    const [isPersonalDataDialogOpen, setIsPersonalDataDialogOpen] = useState(
        false
    );
    const context = useContext(Context);

    const showPrivacy = () => {
        props.history.push('/cookiepolitik');
    };

    const showPersonalDataRules = () => {
        setIsPersonalDataDialogOpen(true);
    };

    const showTerms = () => {
        setIsTermsDialogOpen(true);
    };

    const onFaqClick = () => {
        props.history.push('/faq');
    };

    return (
        <FooterComponent className="footer" path={props.location.pathname}>
            <FooterContentContainer margin="2rem">
                <FlexContainer>
                    <FooterBox className="footer-box--first">
                        <LogoText>BÜLOWSVEJ 10</LogoText>

                        {(window.location.pathname === '/' ||
                            window.location.pathname === '/home') && (
                            <CardsLogoContainer>
                                <Logo src={Dankort} alt="logo"></Logo>
                                <Logo
                                    src={Visa}
                                    alt="Visa logo"
                                    style={{ backgroundColor: 'white' }}
                                ></Logo>
                                <Logo
                                    src={Mastercard}
                                    alt="Mastercard logo"
                                    style={{
                                        width: '42px',
                                        height: '31px',
                                        marginTop: '17px'
                                    }}
                                ></Logo>
                                {/* Removed, because mobilepay is not working now */}
                                {/* <Logo
                                    src={MobilePay}
                                    alt="MobilePay subscriptions logo"
                                    style={{
                                        width: '155px',
                                        height: '68px',
                                        marginTop: '-5px',
                                        marginLeft: '-35px'
                                    }}
                                ></Logo> */}
                                {/* <Logo
              src={MobilePay}
              alt="MobilePay subscriptions logo"
              style={{
                width: '144px',
                height: '75px',
                marginTop: '-5px',
                marginLeft: '-35px',
              }}
            ></Logo> */}
                            </CardsLogoContainer>
                        )}
                    </FooterBox>

                    <FooterBox>
                        <FooterTitle variant="body2">Adresse</FooterTitle>
                        <FooterText variant="body2">
                            Den Lille Musikskole
                            <br />
                            Bülowsvej 10
                            <br />
                            1870 Frederiksberg
                        </FooterText>
                    </FooterBox>
                    <FooterBox>
                        <FooterTitle variant="body2">Kontakt</FooterTitle>
                        <FooterText variant="body2">
                            Telefon: 30 14 01 23
                        </FooterText>
                        <FooterText variant="body2">
                            Mandag - torsdag
                            <br />
                            kl. 10-12
                        </FooterText>

                        <FooterText variant="body2">
                            <FooterLink href="mailto:kontakt@bulowsvej10.dk">
                                kontakt@bulowsvej10.dk
                            </FooterLink>
                        </FooterText>
                    </FooterBox>

                    <FooterBox>
                        <FooterTitle variant="body2">Generelt</FooterTitle>

                        <CookiesLink onClick={() => onFaqClick()}>
                            FAQ
                        </CookiesLink>

                        <CookiesLink onClick={showTerms}>
                            Handels- og medlemsbetingelser
                        </CookiesLink>
                        <CookiesLink onClick={showPrivacy}>
                            Cookiepolitik
                        </CookiesLink>

                        <CookiesLink onClick={showPersonalDataRules}>
                            Persondatapolitik
                        </CookiesLink>
                        {context.state.isOpenPrivacy ? <Privacy /> : <></>}
                    </FooterBox>
                </FlexContainer>

                <SmallText>&#169; Bülowsvej 10 2022</SmallText>
            </FooterContentContainer>
            {isTermsDialogOpen && (
                <TermsDialog close={() => setIsTermsDialogOpen(false)} />
            )}

            {isHouseRulesDialogOpen && (
                <HouseRulesDialog
                    close={() => setIsHouseRulesDialogOpen(false)}
                />
            )}
            {isPersonalDataDialogOpen && (
                <PersonalDataDialog
                    close={() => setIsPersonalDataDialogOpen(false)}
                />
            )}
        </FooterComponent>
    );
}

export default withRouter(Footer);
